<template>
  <div>
    <el-dialog
      :title="$t('bianji')"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closedialog"
      :before-close="handleClose"
      @open="getinfo"
    >
      <el-form
        ref="addform"
        :model="addform"
        :rules="formrules"
        label-width="180px"
      >
        <el-form-item :label="$t('cs.lxmc')" prop="type_name">
          <el-input v-model="addform.type_name"></el-input>
        </el-form-item>
        <el-button type="primary" plain @click="openset">{{
          $t('cs.tjxgpz')
        }}</el-button>
        <el-divider content-position="left">{{ $t('cs.xgpz') }}</el-divider>
        <!-- 相关配置区域 -->
        <div class="setbox" v-for="(it, i) in addform.fields" :key="i">
          <el-divider content-position="left"
            >{{ $t('cs.peizhi') }}{{ it.field }}</el-divider
          >
          <el-input v-model="it.field">
            <template slot="prepend">{{ $t('cs.ziduan') }}</template>
          </el-input>
          <el-input v-model="it.item">
            <template slot="prepend">{{ $t('cs.xiangmu') }}</template>
          </el-input>
          <el-input v-model="it.value">
            <template slot="prepend">{{ $t('cs.mrz') }}</template>
          </el-input>
          <div class="typeclass">
            <span>{{ $t('cs.leixing') }}</span>
            <el-select v-model="it.type">
              <el-option :label="$t('cs.wenben')" value="text"></el-option>
              <el-option :label="$t('cs.tupian')" value="image"></el-option>
            </el-select>
          </div>
          <el-button plain @click="delset(i)">{{ $t('shanchu') }}</el-button>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
        <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
      </span>
    </el-dialog>
    <!-- 相关配置弹窗区域 -->
    <el-dialog title="配置" :visible="setdialog" width="30%">
      <el-form
        ref="setdata"
        :model="setdata"
        :rules="setrules"
        label-width="120px"
      >
        <el-form-item :label="$t('cs.ziduan')" prop="field">
          <el-input v-model="setdata.field"></el-input>
        </el-form-item>
        <el-form-item :label="$t('cs.xiangmu')" prop="item">
          <el-input v-model="setdata.item"></el-input>
        </el-form-item>
        <el-form-item :label="$t('cs.leixing')" prop="type">
          <el-select v-model="setdata.type" :placeholder="$t('qxz')">
            <el-option :label="$t('cs.wenben')" value="text"> </el-option>
            <el-option :label="$t('cs.tupian')" value="image"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('cs.mrz')" prop="value">
          <el-input v-model="setdata.value"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer"
        ><el-button type="primary" @click="saveset">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="closeset">{{ $t('quxiao') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 添加数据对象
      addform: {
        type_name: '',
        fields: []
      },
      // 配置项数据
      setdata: {
        field: '',
        item: '',
        type: '',
        value: ''
      },
      // 表单验证对象
      formrules: {
        type_name: [
          { required: true, message: '请填写类型名称', trigger: 'blur' }
        ]
      },
      // 配置项验证
      setrules: {
        field: [{ required: true, message: '请填写字段', trigger: 'blur' }],
        item: [{ required: true, message: '请填写项目', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'chang' }]
      },
      // 配置弹窗标识
      setdialog: false
    }
  },
  props: ['visible', 'id'],
  methods: {
    // 请求编辑数据
    async getinfo() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get(
        '/admin/config/editpayment/id/' + this.id
      )

      if (data) {
        if (data.code === 200) {
          this.addform.type_name = data.data.type_name
          this.addform.fields = data.data.fields
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    handleClose() {
      this.$emit('close')
    },
    // 关闭asset弹窗
    closedialog() {
      this.$refs.addform.resetFields()
      this.dialogvisible = false
    },
    // 打开配置弹窗
    openset() {
      this.setdialog = true
    },
    // 保存配置项
    saveset() {
      this.$refs.setdata.validate(valid => {
        if (!valid) return false
        // 深拷贝
        this.addform.fields.push(JSON.parse(JSON.stringify(this.setdata)))
        this.$refs.setdata.resetFields()
        this.setdialog = false
      })
    },
    // 删除配置项
    delset(i) {
      this.addform.fields.splice(i, 1)
    },
    // 关闭配置弹窗
    closeset() {
      this.$refs.setdata.resetFields()
      this.setdialog = false
    },
    // 提交保存
    submit() {
      this.$refs.addform.validate(async valid => {
        if (!valid) return false
        this.addform.id = this.id
        const { data } = await this.$http.post(
          '/admin/config/editpayment',
          this.addform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.$t('chenggong'))
            this.$refs.addform.resetFields()
            this.addform.fields = []
            this.handleClose()
            this.$emit('update')
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
        this.handleClose()
      })
    }
  }
}
</script>
<style lang="less">
.setbox {
  .el-input {
    margin-bottom: 20px;
  }
  .typeclass {
    > span {
      font-weight: 600;
      margin-right: 20px;
    }
  }
}
</style>
