<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="addpayment">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="paymentlist"
      border
      style="width: 100%;"
    >
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column
        prop="type_name"
        :label="$t('cs.lxmc')"
      ></el-table-column>
      <el-table-column :label="$t('caozuo')" width="150px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('shanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加弹窗区域 -->
    <addpayment
      :visible="dialogvisible"
      @close="dialogvisible = false"
      @update="getlist"
    ></addpayment>
    <!-- 编辑弹窗区域 -->
    <editpayment
      :visible="editdialogvisible"
      @close="editdialogvisible = false"
      @update="getlist"
      :id="editid"
    ></editpayment>
  </div>
</template>

<script>
import addpayment from '@/components/config/addpayment.vue'
import editpayment from '@/components/config/editpayment.vue'
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      paymentlist: [],
      total: 0,
      loading: true,
      dialogvisible: false,
      editdialogvisible: false,
      editid: ''
    }
  },
  components: {
    addpayment,
    editpayment
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/config/paymentconfig', {
        params: this.queryinfo
      })
      this.paymentlist = data.data.list
      this.loading = false
    },
    // 添加分类弹窗
    addpayment() {
      this.dialogvisible = true
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: this.$t('queding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$http.post('/admin/config/delpayment', {
          id
        })
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 编辑分类弹窗
    edit(id) {
      this.editid = id
      this.editdialogvisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.el-select {
  min-width: 300px;
}
</style>
